import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.458 11h3.364c.102-2.367.566-4.529 1.292-6.189.095-.216.195-.427.301-.63A8.607 8.607 0 0 0 3.458 11ZM12 1.4C6.146 1.4 1.4 6.146 1.4 12c0 5.855 4.746 10.6 10.6 10.6 5.854 0 10.6-4.745 10.6-10.6 0-5.854-4.746-10.6-10.6-10.6Zm0 2c-.249 0-.565.112-.94.474-.38.367-.767.946-1.114 1.739-.6 1.373-1.021 3.252-1.122 5.387h6.352c-.1-2.135-.522-4.014-1.122-5.387-.347-.793-.735-1.372-1.114-1.74-.375-.361-.691-.473-.94-.473Zm5.178 7.6c-.102-2.367-.566-4.529-1.292-6.189a10.241 10.241 0 0 0-.301-.63A8.607 8.607 0 0 1 20.542 11h-3.364Zm-2.002 2H8.824c.1 2.136.522 4.015 1.122 5.388.347.793.735 1.372 1.114 1.739.375.362.691.473.94.473s.565-.11.94-.473c.38-.367.767-.946 1.114-1.74.6-1.372 1.021-3.252 1.122-5.387Zm.409 6.82c.106-.203.206-.414.301-.63.726-1.66 1.19-3.822 1.292-6.19h3.364a8.608 8.608 0 0 1-4.957 6.82Zm-7.17 0a10.263 10.263 0 0 1-.301-.63c-.726-1.66-1.19-3.822-1.292-6.19H3.458a8.608 8.608 0 0 0 4.957 6.82Z"
  />,
  'Globe1'
)
